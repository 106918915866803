import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = (props) => (
      <Layout>
        <SEO description="BuilderCover is the services concierge to building developers and owners. According to your project, we put our clients in touch with the highest performing vendors across many service industries."
        title="Contact Us" 
        keywords={[`construction`, `building services`, `engineering`, `construction insurance`]} />
        <section className="hero is-small is-primary">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-1 is-hidden-touch">
                </div>
                <div className="column is-8">
                  <h2 className="subtitle is-size-4 is-uppercase has-text-info">
                    Contact Us
                  </h2>
                  <h1 className="title has-text-white is-size-1-desktop is-size-2-touch has-text-weight-bold">
                    Have a question? <br/>Just ask!
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section container">
        <div className="columns">
        <div className="column is-three-fifths is-offset-one-fifth">
          <form name="contact" action="/contact-success" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label className="label" htmlFor="name">Name</label>
              <input className="input" type="text" placeholder="Full Name" name="name" id="name" />
            </div>
            <div className="field half">
              <label className="label" htmlFor="email">Email</label>
              <input className="input" type="email" placeholder="email@example.com" name="email" id="email" />
            </div>
            <div className="field">
              <label className="label" htmlFor="message">Message</label>
              <textarea className="textarea" name="message" id="message" rows="10" />
            </div>
            <div className="field is-grouped">
              <div className="control">
                <button className="button is-link is-medium" type="submit">Submit</button>
              </div>
              <div className="control">
                <button className="button is-link is-light is-medium" type="reset">Clear</button>
              </div>
            </div>
          </form>
          <div ClassName="columns">
            <div className="column is-4 is-offset-4">
              <Img fluid={props.data.cogsImage.childImageSharp.fluid}/>
            </div>
          </div>
        </div>
        </div>  
        </section> 
      </Layout>
    )

export default ContactPage

export const pageQuery = graphql `
  query {
    cogsImage: file(relativePath: { eq: "cogs.png"}) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;